import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@core/services/api.service';
import { LocalNotificationService } from '@core/services/local-notification.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
})
export class SignUpFormComponent implements OnInit {
  public form: FormGroup;
  public tooglePassTriger: EventEmitter<string>;
  public isLoading: boolean;

  public get email() {
    return this.form.get('email');
  }

  public get phone() {
    return this.form.get('phone');
  }

  public get agreement(): boolean {
    return this.form.get('agreement').value;
  }

  constructor(
    private readonly apiService: ApiService,
    private readonly localNotificationService: LocalNotificationService
  ) {
    this.tooglePassTriger = new EventEmitter();
  }

  ngOnInit() {
    this.initForms();
  }

  public initForms() {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [
        Validators.required,
        Validators.pattern('[- +()0-9]{10,13}'),
      ]),
      agreement: new FormControl(false, Validators.required),
    });
  }

  public emitTooglePass(id: string) {
    this.tooglePassTriger.emit(id);
  }

  public submit() {
    this.isLoading = true;

    let dto = {
      email: this.email.value,
      phone: this.phone.value,
    };

    this.apiService
      .executeRequest('post', '/auth/register', dto)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.localNotificationService.showNotification(
            'shared.local-notifications.confirm-send',
            'toast-success'
          );
          this.form.reset();
        },
        (error) => {
          if (
            JSON.stringify(error.error.errorMessages) ===
            '[{"email":"Must be an email"}]'
          ) {
            this.localNotificationService.showNotification(
              'shared.local-notifications.wrong-email',
              'toast-error'
            );
          } else if (
            error?.error?.message ===
            'An account is already registered with your email address'
          ) {
            this.localNotificationService.showNotification(
              'shared.local-notifications.already-register',
              'toast-error'
            );
          } else {
            this.localNotificationService.showNotification(
              error?.error?.message ||
                JSON.stringify(error.error.errorMessages),
              'toast-error'
            );
          }
        }
      );
  }
}
